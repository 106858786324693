.mainContainer {
    width: 100%;
    min-height: 60vh;
    margin-bottom: 10rem;
    margin-top: 3rem;
}

.block-carousel {
    height: auto;
    min-height: 65vh;
    border: 1px solid rgb(222, 222, 222);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
}

/* Левая колонка с текстом */
.info-comm-carousel {
    text-align: left;
    flex: 1;
    padding: 1rem;
}

/* Правая колонка с изображением, именем и городом */
.image-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.imgBG2 {
    min-height: 70vh;
    height: 100%;
    background-size: cover;
    background-position: center;
    width: 100%;
    position: relative;
}

.review-image {
    width: 100%;
    max-width: 350px;
    height: auto;
    object-fit: cover;
    max-height: 70vh;
    margin-bottom: 2rem;
}

.carousel-title-main {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-top: 10rem;
}

.carousel-title {
    font-size: 2.4rem;
    margin-bottom: 20px;
    text-align: left;
    color: #800000;
}

.carousel-text {
    font-size: 1.25rem;
    font-weight: 400;
    width: 100%;
    text-align: left;
}

.carousel-text-name {
    font-size: 1.5rem;
    font-weight: 400;
    width: 100%;
    color: #800000;
}

.carousel-text-reference {
    color: rgb(0, 116, 248);
    font-size: 1.25rem;
    font-weight: 400;
    width: auto;
    text-align: left;
    display: inline;
    border-bottom: 1px dashed rgb(0, 116, 248);
}

/* Центрируем стрелки под каруселью */
.carousel-controls {
    display: flex;
    justify-content: center;
    margin-top: -8rem;
}

.carousel-arrow {
    font-size: 3rem;
    color: white;
    background-color: #800000;
    border-radius: 50%;
    padding: 10px;
    margin: 0 15px;
    cursor: pointer;
    transition: transform 0.2s;
}

.carousel-arrow:hover {
    transform: scale(1.1);
}

/* Адаптация для планшетов */
@media (max-width: 1024px) {
    .block-carousel {
        padding: 1rem;
    }

    .carousel-title-main {
        font-size: 1.8rem;
        margin-top: 2rem;
    }

    .carousel-title {
        font-size: 2rem;
    }

    .carousel-text {
        font-size: 1.1rem;
    }

    .review-image {
        max-width: 300px;
    }

    .carousel-controls {
        margin-top: -4rem;
    }
}

/* Адаптация для мобильных устройств */
@media (max-width: 767px) {
    .block-carousel {
        flex-direction: column;
        padding: 1rem;
        min-height: 80vh;
        /* Увеличиваем минимальную высоту на мобильных */
    }

    .imgBG2 {
        min-height: 100vh;
    }

    .carousel-title-main {
        font-size: 1.5rem;
        margin-top: 2rem;
    }

    .carousel-title {
        font-size: 1.5rem;
    }

    .carousel-text {
        font-size: 1rem;
    }

    .carousel-text-name {
        font-size: 1.3rem;
    }

    .review-image {
        max-width: 250px;
    }

    .carousel-controls {
        margin-top: -10rem;
    }

    .mainContainer {
        margin-top: 0rem;
    }
}

@media (max-width: 420px) {
    .block-carousel {
        flex-direction: column;
        padding: 1rem;
        min-height: 80vh;
        /* Увеличиваем минимальную высоту на мобильных */
    }

    .imgBG2 {
        min-height: 130vh;
    }

    .carousel-title-main {
        font-size: 1.5rem;
        margin-top: 2rem;
    }

    .carousel-title {
        font-size: 1.5rem;
    }

    .carousel-text {
        font-size: 1rem;
    }

    .carousel-text-name {
        font-size: 1.3rem;
    }

    .review-image {
        max-width: 250px;
    }

    .carousel-controls {
        margin-top: -10rem;
    }

    .mainContainer {
        margin-top: 0rem;
    }
}

/* Высота карусели для телефонов в альбомной ориентации */
@media (max-height: 600px) and (max-width: 767px) {
    .block-carousel {
        min-height: 90vh;
    }

    .imgBG2 {
        min-height: 100vh;
    }

    .carousel-controls {
        margin-top: -10rem;
    }

    .mainContainer {
        margin-top: 0rem;
    }
}