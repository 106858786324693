/* Основные стили */
.row {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.AboutUsSection {
    margin-top: 8rem;
}

.col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}

.position-relative {
    position: relative;
    display: inline-block;
}

.main-about-us-img {
    max-width: 100%;
    height: auto;
    position: relative;
    z-index: 2;
}

.bg-vector-img {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    height: auto;
    z-index: 1;
    object-fit: contain;
}

.text-info-about-us {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.main-about-us-mini-title {
    font-size: 1.2rem;
    padding: 8px 12px;
    background-color: rgb(244, 244, 244);
    margin-bottom: 2rem;
    margin-top: 2rem;
    border-radius: 10px;
}

.main-about-us-title {
    font-size: 2.5rem;
    width: 35rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 3rem;
}

.about-us-info {
    font-size: 1.4rem;
    width: 40rem;
    line-height: 1.4;
    margin-bottom: 2rem;
}

.main-about-us-button {
    padding: 2rem 4rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    background-color: #800000;
    border: none;
    border-radius: 10px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.main-about-us-button:hover {
    background-color: #5b0101;
}

/* Адаптив для планшетов и телефонов до 1200px */
@media (max-width: 1200px) {
    .main-about-us-title {
        font-size: 2.2rem;
        width: 90%;
        margin-top: 1rem;
    }

    .about-us-info {
        font-size: 1.1rem;
        width: 90%;
        margin-top: 1rem;
    }

    .main-about-us-button {
        padding: 1rem 2rem;
        font-size: 1rem;
        margin-top: 2rem;
    }

    .col {
        text-align: center;
        padding: 0;
    }

    .position-relative {
        width: 100%;
        padding: 2rem;
    }

    /* Скрытие картинки на планшетах и телефонах */
    .main-about-us-img {
        max-width: 80%;
        height: auto;
    }

    /* Центрируем текст в одной колонке */
    .text-info-about-us {
        text-align: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .AboutUsSection {
        margin-top: 4rem;
    }
}

/* Адаптив для мобильных устройств до 1023px */
@media (max-width: 1023px) {
    .main-about-us-title {
        font-size: 1.8rem;
        width: 90%;
        margin-top: 1rem;
    }

    .about-us-info {
        font-size: 1rem;
        width: 90%;
        margin-top: 1rem;
    }

    .main-about-us-button {
        padding: 1rem 2rem;
        font-size: 1rem;
        margin-top: 2rem;
    }

    .col {
        text-align: center;
        padding: 0;
    }

    .position-relative {
        width: 100%;
        padding: 2rem;
    }

    /* Скрытие картинки на мобильных устройствах */
    .main-about-us-img {
        max-width: 90%;
        height: auto;
    }

    .bg-vector-img {
        display: none;
    }

    /* Центрируем текст в одной колонке */
    .text-info-about-us {
        text-align: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .AboutUsSection {
        margin-top: 3rem;
    }
}

/* Адаптив для очень маленьких экранов до 480px */
@media (max-width: 480px) {
    .main-about-us-title {
        font-size: 1.8rem;
        width: 90%;
    }

    .about-us-info {
        font-size: 0.9rem;
        width: 90%;
        margin-top: 1rem;
    }

    .main-about-us-button {
        padding: 0.8rem 1.5rem;
        font-size: 0.9rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .position-relative {
        padding: 1rem 0;
    }

    .row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .col {
        width: 100%;
        text-align: center;
    }

    .AboutUsSection {
        margin-top: 2rem;
    }
}