.footer {
    background-color: #f8f9fa;
    border-top: 1px solid #ddd;
}

.footer-link {
    text-decoration: none;
    font-size: 1rem;
    color: gray;
    font-weight: 500;
}

.footer-link:hover {
    text-decoration: underline;
}

@media (max-width: 767px) {
    .footer-link {
        font-size: 1.1rem;
    }
}

/* Убираем все отступы между колонками */
.footer .row {
    margin-right: 0;
    margin-left: 0;
}

.footer .col {
    padding-left: 0;
    padding-right: 0;
}

/* Убираем отступы между колонками */
.footer .col-xs-4,
.footer .col-md-4 {
    padding-left: 0;
    padding-right: 0;
}