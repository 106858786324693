/* Основные стили */
.adress-section {
    margin-top: 10rem;
}

.position-relative {
    position: relative;
    display: inline-block;
}

.main-adress-img {
    width: 30rem;
    height: auto;
    position: relative;
    z-index: 2;
}

.bg-vector-img {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: auto;
    z-index: 1;
    object-fit: contain;
}

.text-info-adress {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.main-adress-mini-title {
    font-size: 1.2rem;
    padding: 8px 12px;
    background-color: rgb(244, 244, 244);
    margin-bottom: 2rem;
    margin-top: 2rem;
    border-radius: 10px;
}

.main-adress-title {
    font-size: 3rem;
    width: 35rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 1rem;
}

.main-adress-text {
    font-size: 1.4rem;
    width: 30rem;
    line-height: 1.4;
    color: #555;
    margin-bottom: 2.5rem;
}

.main-adress-button {
    padding: 2rem 4rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    background-color: #800000;
    border: none;
    border-radius: 10px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.main-adress-button:hover {
    background-color: #5b0101;
}

/* Адаптив для планшетов и телефонов до 1024px */
@media (max-width: 1200px) {
    .main-adress-title {
        font-size: 2rem;
        width: 100%;
        margin: 0 auto;
    }

    .main-adress-mini-title {
        margin-top: -15rem;
    }

    .main-adress-text {
        font-size: 1rem;
        width: 100%;
        margin: 0 auto;
        margin-top: 1rem;
    }

    .main-adress-button {
        padding: 1rem 2rem;
        font-size: 1rem;
        margin-top: 1rem;
    }

    .col {
        text-align: center;
        padding: 0;
    }

    .position-relative {
        width: 100%;
        padding: 2rem;
    }

    .main-adress-img {
        width: 350px;
    }

    .text-info-adress {
        text-align: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .AdressSection {
        margin-top: 4rem;
    }
}

/* Адаптив для мобильных устройств до 768px */
@media (max-width: 768px) {
    .main-adress-title {
        font-size: 1.8rem;
    }

    .main-adress-mini-title {
        margin-top: -15rem;
    }

    .main-adress-text {
        font-size: 1rem;
        margin-top: 1rem;
    }

    .main-adress-button {
        padding: 1rem 2rem;
        font-size: 1rem;
        margin-top: 1rem;
    }

    .col {
        text-align: center;
        padding: 0;
    }

    .position-relative {
        width: 100%;
        padding: 2rem;
    }

    .main-adress-img {
        display: none;
    }

    .AdressSection {
        margin-top: 3rem;
    }
}

/* Адаптив для маленьких экранов до 480px */
@media (max-width: 480px) {
    .main-adress-title {
        font-size: 1.8rem;
        width: 90%;
        margin: 0 auto;
    }

    .main-adress-mini-title {
        margin-top: -12rem;
    }

    .main-adress-text {
        font-size: 0.9rem;
        margin-top: 1rem;
    }

    .main-adress-button {
        padding: 0.8rem 1.5rem;
        font-size: 0.9rem;
        margin-top: 2rem;
    }

    .position-relative {
        padding: 1rem 0;
    }

    .row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .col {
        width: 100%;
        text-align: center;
    }

    .AdressSection {
        margin-top: 2rem;
    }
}