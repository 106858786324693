/* PopUp1.css */

.custom-popup .popup-body {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 20px;
    max-width: 1100px;
    /* Увеличиваем максимальную ширину попапа */
    width: 100%;
    /* Полная ширина попапа */
    margin: 0 auto;
    /* Центрируем попап */
}

.custom-popup-dialog {
    max-width: 1100px;
    /* Увеличьте значение, например, до 1000px или более */
    width: 90%;
    /* Задайте процент для адаптивной ширины */
    margin: 0 auto;
    /* Центрирует модальное окно */
}

.popup-content {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
}

.popup-image {
    flex: 1;
    max-width: 50%;
    /* Изображение занимает половину ширины */
}

.popup-image img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.popup-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.popup-title {
    font-weight: bold;
    font-size: 2.8rem;
    text-align: left;
}

.popup-title-word {
    color: #800000;
    font-weight: bold;
    font-size: 2.8rem;
    text-align: left;
}

.popup-description {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: left;
}

.popup-info {
    display: flex;
    flex-wrap: wrap;
}

.info-item {
    width: 50%;
    margin-bottom: 1rem;
}

.info-number {
    font-size: 1.8rem;
    font-weight: bold;
    color: #800000;
    text-align: left;
}

.info-text {
    font-size: 0.9rem;
    color: #555;
    text-align: left;
}

/* Стиль для кнопки закрытия */
.close-button {
    font-size: 1.5rem;
    color: #800000;
    /* Бордовый цвет крестика */
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    /* Сохраняем прозрачный фон */
    border: none;
    /* Убираем обводку */
    z-index: 10;
    /* Убедитесь, что кнопка на переднем плане */
}

/* Для крестика в кнопке */
.close-button .btn-close {
    color: #800000;
    /* Бордовый цвет крестика */
    font-size: 1.5rem;
    /* Размер иконки */
}

/* При наведении */
.close-button:hover {
    background-color: #800000;
    /* Бордовый фон при наведении */
    color: white !important;
    /* Белый цвет крестика при наведении */
}

/* Убираем фокусное обрамление */
.close-button:focus {
    outline: none;
}

@media (max-width: 768px) {
    .popup-content {
        flex-direction: column;
    }

    .popup-image {
        max-width: 100%;
        margin-bottom: 1.5rem;
        height: auto;
    }

    .popup-text {
        padding-left: 0;
    }
}