.contact-form {
    max-width: 900px;
    border-color: #800000;
    padding: 40px;
    /* Увеличен padding для большей высоты формы */
}

.contact-form h1 {
    font-size: 1.75rem;
    color: #333333;
    font-weight: 700;
}

.contact-form p {
    color: #555555;
    font-size: 1.125rem;
}

.contact-form .form-control {
    background-color: #f8f9fa;
    border: none;
    font-size: 1.125rem;
    padding: 22px;
}

.contact-form .submit-btn {
    background-color: #800000;
    color: #ffffff;
    padding: 22px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
}

.contact-form .submit-btn:hover {
    background-color: #660000;
}

.form-check-label {
    color: #800000;
}

/* Для чекбокса рядом с текстом */
.form-check .d-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-check-input {
    margin-right: 10px;
    /* Добавлен отступ справа от чекбокса */
}

.form-check-input:checked {
    margin-right: 10px;
    background-color: #800000;
    border-color: #800000;
    /* Добавлен отступ справа от чекбокса */
}