.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 30px 0;
    border-bottom: 1px solid rgb(232, 232, 232);
    position: relative;
    flex-wrap: wrap;
}

.header-left {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    /* Не позволяет уменьшать блок с названием */
}

.name-header-text {
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none;
    color: black;
}

.circle {
    width: 40px;
    height: 40px;
    background-color: #800000;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
}

/* Стили для навигационного списка */
.header-nav .nav-list {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 30px;
    margin: 0;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.header-nav .nav-list a {
    text-decoration: none;
    color: black;
}

.info-block {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.info-block svg {
    font-size: 1.3rem;
    margin-top: 4px;
}

.info-header-text,
.info-header-contact-text {
    font-size: 1rem;
    text-align: left;
}

.telefon-header-text {
    font-size: 1.2rem;
    font-weight: bold;
}

/* Стили для кнопки меню */
.menu-toggle {
    display: none;
    background: transparent;
    border: none;
    font-size: 2rem;
    color: black;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    /* Центрирование кнопки по вертикали */
}

/* Мобильный адаптив */
@media (max-width: 768px) {
    .header-nav .nav-list {
        display: none;
        /* Скрываем меню по умолчанию */
    }

    .menu-toggle {
        display: block;
        /* Показываем кнопку меню */
    }

    .header-nav.open .nav-list {
        display: flex;
        /* Показываем меню, когда кнопка нажата */
        flex-direction: column;
        /* Элементы меню будут располагаться вертикально */
        gap: 15px;
        margin-top: 20px;
    }

    .dropdown-menu {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 10px;
        padding: 10px 0;
    }

    .nav-list-mobile {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .header-left {
        justify-content: flex-start;
    }
}