/* Общие стили для контейнера */
.container {
    padding: 2rem 1rem;
    /* Добавляем отступы для основного контейнера */
}

/* Заголовок каталога */
.catalog-title {
    margin-top: 8rem;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 5rem;
}

/* Стили для карточек товаров */
.catalog-item {
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    margin-bottom: 2rem;
    /* Добавим отступ между карточками */
}

.catalog-item:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.catalog-item img {
    width: 100%;
    height: auto;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    margin-bottom: 1rem;
}

.catalog-info {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
}

.catalog-item h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
}

.catalog-item-text {
    font-size: 1.1rem;
    width: 18rem;
    margin-bottom: 1rem;
}

.catalog-item-price {
    font-size: 1.8rem;
    width: 18rem;
    margin-bottom: 1rem;
}

.catalog-item button {
    padding: 1rem 2.5rem;
    font-size: 1.2rem;
    color: #fff;
    background-color: #800000;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.catalog-item button:hover {
    background-color: #5b0101;
}

/* Адаптивность для планшетов и мобильных устройств (до 1024px) */
@media (max-width: 1024px) {
    .catalog-title {
        margin-top: 2rem;
        /* Уменьшаем отступ для планшетов */
        font-size: 2rem;
    }

    .catalog-item h3 {
        font-size: 1.3rem;
    }

    .catalog-item-text {
        font-size: 1rem;
        width: 100%;
        /* Убираем фиксированную ширину */
        margin-bottom: 0.8rem;
    }

    .catalog-item-price {
        font-size: 1.5rem;
        width: 100%;
        /* Убираем фиксированную ширину */
        margin-bottom: 0.8rem;
    }

    .catalog-item button {
        padding: 0.8rem 2rem;
        font-size: 1rem;
    }

    .catalog-item {
        flex: 1 1 45%;
        /* Делаем карточки чуть меньше, чтобы вместились в ряды */
    }

    .catalog-info {
        padding: 1.5rem;
    }

    .container {
        padding: 1rem;
        /* Уменьшаем отступы */
    }

    /* Стили для карточек в одном ряду */
    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
}

/* Адаптивность для мобильных устройств (до 768px) */
@media (max-width: 768px) {
    .catalog-title {
        font-size: 1.8rem;
        margin-top: 1.5rem;
    }

    .catalog-item {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .catalog-item h3 {
        font-size: 1.2rem;
    }

    .catalog-item-text {
        font-size: 1rem;
        margin-bottom: 0.6rem;
    }

    .catalog-item-price {
        font-size: 1.5rem;
    }

    .catalog-item button {
        padding: 0.7rem 1.5rem;
        font-size: 1rem;
    }

    .catalog-info {
        padding: 1rem;
    }

    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/* Адаптивность для маленьких экранов (до 480px) */
@media (max-width: 480px) {
    .catalog-title {
        font-size: 1.5rem;
        margin-top: 1rem;
    }

    .catalog-item h3 {
        font-size: 1rem;
    }

    .catalog-item-text {
        font-size: 0.9rem;
        margin-bottom: 0.4rem;
    }

    .catalog-item-price {
        font-size: 1.3rem;
    }

    .catalog-item button {
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
    }

    .catalog-item {
        width: 100%;
    }
}