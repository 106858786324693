.main-gallery {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-gallery-title {
    font-size: 1.8rem;
    margin-bottom: 10px;
    font-weight: bold;
}

.main-gallery-mini-title {
    font-size: 1.2rem;
    color: #6c757d;
    margin-bottom: 20px;
}

.gallery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* Равномерное распределение элементов по строкам */
    margin: 0 auto;
    /* Центрируем контейнер */
    gap: 5px;
    /* Равные отступы между элементами */
    width: 100%;
    max-width: 1200px;
    /* Максимальная ширина контейнера */
}

.gallery-item {
    flex: 1 1 calc(33.33% - 5px);
    /* 3 элемента в строке с отступом */
    margin-bottom: 5px;
    /* Расстояние между рядами */
}

.gallery-img-item {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin: 0;
    /* Убираем отступы */
}

@media (max-width: 1024px) {
    .main-gallery-title {
        margin-top: -5rem;
    }
}

@media (max-width: 724px) {
    .main-gallery-title {
        margin-top: -5rem;
    }
}